import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  private _cartUuidKey = 'cartUuid'
  private _tokenKey = 'token'
  private _serviceKey = 'serviceKey'

  constructor() {}

  private _set(key: string, value: string | null) {
    if (value !== null) {
      localStorage.setItem(key, value)
    } else {
      localStorage.removeItem(key)
    }
  }

  get token(): string | null {
    return localStorage.getItem(this._tokenKey)
  }

  set token(value: string | null) {
    this._set(this._tokenKey, value)
  }

  get serviceKey(): string | null {
    return localStorage.getItem(this._serviceKey)
  }

  set serviceKey(value: string | null) {
    this._set(this._serviceKey, value)
  }

  get cartUuid(): string | null {
    return localStorage.getItem(this._cartUuidKey)
  }

  set cartUuid(value: string | null) {
    this._set(this._cartUuidKey, value)
  }
}
