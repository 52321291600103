import { Injectable, Injector, NgZone } from '@angular/core'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import { SnackbarMessageComponent } from '../../shared/components/snackbar-message/snackbar-message.component'

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public constructor(private injector: Injector, private snackBar: MatSnackBar, private zone: NgZone) {}

  public showSuccess(messages: Array<string>, title?: string) {
    this.openSnackbar(messages, false, title)
  }

  public showError(messages: Array<string>, title?: string) {
    this.openSnackbar(messages, true, title)
  }

  private openSnackbar(messages: Array<string>, error: boolean, title?: string) {
    let panelClasses = ['zaidra-snack-bar', 'mat-toolbar']
    const data = { messages, error, title }
    if (error) {
      panelClasses.push('mat-warn')
    } else {
      panelClasses.push('mat-primary')
    }
    this.zone.run(() => {
      this.snackBar.openFromComponent(SnackbarMessageComponent, {
        data,
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: panelClasses,
      })
    })
  }
}
