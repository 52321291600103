import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { LocalStorageService } from '@core/services/local-storage.service'

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
  constructor(private ls: LocalStorageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.ls.token

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        }
      })
    }

    request = request.clone({
      setHeaders: {
        'Accept-Language': 'en',
      }
    })

    return next.handle(request)
  }
}
