import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  public constructor() {}

  public getClientErrorMessage(error: ErrorEvent): string {
    return error.message ? error.message : error.toString()
  }

  public getServerErrorMessage(error: HttpErrorResponse): Array<string> {
    let err = error.error

    //TODO:fix this on the backend side
    if (typeof err === 'string') {
      try {
        err = JSON.parse(err)
      } catch (e) {
        console.error(e)
      }
    }

    const messages: Array<string> = []

    if (err.message === 'error.validation') {
      err.fieldErrors.forEach((error: ValidationMessage) => {
        messages.push(`${error.field} ${error.message}`)
      })

      if (messages.length) {
        return messages
      }
    }

    const message = err?.detail || error.message || err?.title

    return [message]
  }
}

type ValidationMessage = {
  field: string
  message: string
  objectName: string
}
