import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { REDIRECT_WHEN_UNAUTHENTICATED } from '@core/interceptors/tokens'
import { Router } from '@angular/router'
import { AuthUserService } from '@core/services/auth-user.service'

@Injectable()
export class UnAuthenticatedInterceptor implements HttpInterceptor {
  public constructor(private authService: AuthUserService, private router: Router) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authService.logoutAuthUser()

          if (this.shouldRedirectToLogin()) {
            this.router.navigate(['/auth/login'])
          }
        }

        return throwError(() => error)
      })
    )
  }

  private shouldRedirectToLogin() {
    const url = this.router.routerState.snapshot.url
    return url.startsWith('/checkout') || url.startsWith('/account')
  }
}
