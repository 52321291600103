import { Injectable } from '@angular/core'
import { TranslateLoader } from '@ngx-translate/core'
import { of } from 'rxjs'
// @ts-ignore
import en from '../assets/i18n/en.json'

@Injectable({
  providedIn: 'root',
})
export class AppTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    switch (lang) {
        case 'en':
          return of({ ...en })
        default:
          throw Error(`Unsupported language: ${lang}`)
    }
  }
}
