<div class="d-flex main-wrapper">

  <div>
      <span class="icon-wrapper me-3" style="line-height: 2">
        <mat-icon class="text-danger" *ngIf="data.error">error</mat-icon>
        <mat-icon class="text-primary" *ngIf="!data.error">info</mat-icon>
      </span>
  </div>


  <div class="d-flex flex-grow-1 flex-column">

    <div class="d-flex justify-content-between">

      <div class="d-flex">
        <span class="fs-l">{{ data.title ?? (data.error ? 'Error' : 'Success') }}</span>
      </div>

      <div class="btn-wrapper">
        <button mat-icon-button (click)="dismissSnackbar()"><mat-icon inline class="fs-l">close</mat-icon></button>
      </div>

    </div>

    <div class="fs-s">
      <div class="lh-base text-break" [class.mb-2]="(i+1) !== data.messages.length" *ngFor="let message of data.messages; index as i">
        {{ message }}
      </div>
    </div>



  </div>


</div>

