import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable, Injector } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { ErrorService } from '@core/services/error.service'
import { NotificationService } from '@core/services/notification.service'
import { SHOW_HTTP_MSGS } from '@core/interceptors/tokens'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public constructor(private injector: Injector) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {

        if (error.status !== 401 && request.context.get(SHOW_HTTP_MSGS)) {
          const errorService = this.injector.get(ErrorService)
          const notifier = this.injector.get(NotificationService)
          const messages = errorService.getServerErrorMessage(error)
          notifier.showError(messages)
        }

        return throwError(() => error)
      })
    )
  }
}
