import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { finalize, Observable } from 'rxjs'
import { LoadingService } from '@core/services/loading.service'

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private loader: LoadingService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    this.loader.increment()

    return next.handle(request).pipe(
      finalize(() => {
        this.loader.decrement()
      })
    )
  }
}
