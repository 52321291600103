// noinspection AllyPlainJsInspection

import { environment } from 'src/environments/environment'
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core'

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  private message: string = 'Translation not found'

  handle(params: MissingTranslationHandlerParams) {
    if (!environment.production) {
      console.error(`No Translation! Key: ${params.key}, Lang: ${params.translateService.currentLang}`)
    }

    if (params.interpolateParams) {
      return (params.interpolateParams as any)['default'] || params.key
    }

    return this.message
  }
}
