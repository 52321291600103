import { BehaviorSubject } from 'rxjs'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private activeRequests = 0
  private _loading = new BehaviorSubject<boolean>(false)
  public readonly loading$ = this._loading.asObservable()

  constructor() {}

  increment() {
    this.activeRequests += 1
    this.show()
  }

  decrement() {
    this.activeRequests -=1
    if (this.activeRequests <= 0) {
      this.hide()
    }
  }

  private show() {
    this._loading.next(true)
  }

  private hide() {
    this._loading.next(false)
  }
}
