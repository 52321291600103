import { Component, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../environments/environment'
import { map } from 'rxjs'
import { LocalStorageService } from '@core/services/local-storage.service'
import { AutoDestroy } from '@core/common/auto-destroy'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AutoDestroy implements OnInit {
  title = 'eshop'
  keyLoaded = false
  pwaInstallEvent: Event

  constructor(
    private localStorageService: LocalStorageService,
    private httpClient: HttpClient
  ) {
    super()

    this.keyLoaded = !!this.localStorageService.serviceKey
  }

  ngOnInit() {
    if (! this.keyLoaded) {
      this.httpClient.get(`${environment.lunaServiceUrl}/luna-service.json`).pipe(map((resp: any) => resp.key))
        .subscribe((key: string) => {
          this.keyLoaded = true
          return (this.localStorageService.serviceKey = key)
        })
    }
  }

  // @HostListener('window:beforeinstallprompt', ['$event'])
  // onbeforeinstallprompt(e: Event) {
  //   e.preventDefault()
  //   this.pwaInstallEvent = e
  // }
}
