import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { MicroserviceType } from '@core/enums/microservice-type.enum'
import { LocalStorageService } from '@core/services/local-storage.service'

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private localStorage: LocalStorageService) {}

  public get<T>(api: string, ms?: any, options?: any): Observable<any> {
    return this.request('get', api, ms, options)
  }

  // @todo set ms type any until all apis are implemented
  public post<T>(api: string, body: any, ms?: any, options?: any): Observable<any> {
    options = { ...options, body }
    return this.request('post', api, ms, options)
  }

  public put<T>(api: string, body: any, ms?: any, options?: any): Observable<any> {
    options = { ...options, body }
    return this.request('put', api, ms, options)
  }

  public delete<T>(api: string, ms?: any, options?: any): Observable<any> {
    return this.request('post', api, ms, options)
  }

  public request<T>(method: string, api: string, ms?: any, options?: any): Observable<any> {
    options = this.addServiceTokenToHeader(options)
    const url = this.buildEP(api, ms)

    return this.http.request<T>(method, url, options)
  }

  private buildEP(api: string, ms?: MicroserviceType) {
    let url

    if (ms) {
      url = `${environment.apiUrl}/services/${ms}/api/${api}`
    } else {
      url = `${environment.mApiUrl}/${api}`
    }

    return url
  }

  private addServiceTokenToHeader(options?: any) {
    options = options || {}
    options.headers = { ...options.headers, 'Luna-Service': this.localStorage.serviceKey, 'ngsw-bypass': "" }
    return options
  }

}
