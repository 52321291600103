import { Subject } from 'rxjs'
import { Injectable, OnDestroy } from '@angular/core'

@Injectable()
export abstract class AutoDestroy implements OnDestroy {

  protected destroy$: Subject<void> = new Subject()

  ngOnDestroy(): void {
    this.destroy$.next()  // trigger the unsubscribe
    this.destroy$.complete() // finalize & clean up the subject stream
  }

}
