import { Injectable } from '@angular/core'
import { BehaviorSubject, EMPTY, finalize, Observable, tap } from 'rxjs'
import { User } from '@core/models/user.model'
import { ApiService } from '@core/services/api.service'
import { MicroserviceType } from '@core/enums/microservice-type.enum'
import { REDIRECT_WHEN_UNAUTHENTICATED, SHOW_HTTP_MSGS } from '@core/interceptors/tokens'
import { HttpContext } from '@angular/common/http'
import { LocalStorageService } from '@core/services/local-storage.service'

@Injectable({
  providedIn: 'root',
})
export class AuthUserService {
  private userSource: BehaviorSubject<User|null> = new BehaviorSubject(undefined as any)

  private pendingRequests = { 'authUser': false }

  authUser: Observable<User|null> = this.userSource.asObservable()

  constructor(private api: ApiService, private localStorage: LocalStorageService) {}

  fetchAuthUser() {

    if (! this.localStorage.token) {
      this.userSource.next(null)
      return EMPTY
    }

    this.pendingRequests['authUser'] = true
    const options = { context: new HttpContext().set(SHOW_HTTP_MSGS, false).set(REDIRECT_WHEN_UNAUTHENTICATED, false) }

    return this.api.get('account', MicroserviceType.usr, options)
      .pipe(finalize(() => this.pendingRequests['authUser'] = false))
      .pipe(tap(user => this.userSource.next(user)))
  }

  getAuthUser() {
    if (this.userSource.value === undefined && !this.pendingRequests['authUser']) {
      this.fetchAuthUser().subscribe()
    }

    return this.authUser
  }

  logoutAuthUser() {
    this.localStorage.token = null
    this.userSource.next(null)
  }

  setAuthToken(token: string) {
    this.localStorage.token = token
    return this.fetchAuthUser()
  }
}
