import { NgModule } from '@angular/core'
import { BrowserModule, DomSanitizer } from '@angular/platform-browser'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { JWTInterceptor } from '@core/interceptors/jwt.interceptor'
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { AppTranslateLoader } from './app-translate.loader'
import { AccountComponent } from './account/account.component'
import { LoaderInterceptor } from '@core/interceptors/loader.interceptor'
import { CustomMissingTranslationHandler } from '@core/utils/custom-missing-translation-handler'
import { WithCredentialsInterceptor } from '@core/interceptors/withCredentials.interceptor'
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar'
import { ErrorInterceptor } from '@core/interceptors/error.interceptor'
import { UnAuthenticatedInterceptor } from '@core/interceptors/unAuthenticated.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    AccountComponent,
    // InputComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatSnackBarModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      isolate: true,
      loader: {
        provide: TranslateLoader,
        useClass: AppTranslateLoader,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
      },

    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !environment.local,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnAuthenticatedInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.setDefaultFontSetClass('material-icons-outlined')

    iconRegistry.addSvgIcon(
      'favorite_open',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/favorite.svg')
    )

    iconRegistry.addSvgIcon(
      'swiper-next',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/swiper-next.svg')
    )
    iconRegistry.addSvgIcon(
      'ruler',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ruler.svg')
    )
    iconRegistry.addSvgIcon(
      'package_box',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/package-box.svg')
    )

  }
}
